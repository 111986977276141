import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BasicLayout'),
          LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    name: '首页',
    routes: [
      {
        path: '/',
        redirect: '/home',
        exact: true,
      },
      {
        path: '/home',
        name: 'home',
        text: '首页',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/pages/mobile/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../mobile/zjghpt'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/components/PageLoading/index')
                .default,
            })
          : require('../mobile/zjghpt').default,
        exact: true,
      },
      {
        path: '/ancaredynamics',
        name: 'ancaredynamics',
        text: '安肯动态',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/pages/mobile/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../mobile/ancaredynamics'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/components/PageLoading/index')
                .default,
            })
          : require('../mobile/ancaredynamics').default,
        exact: true,
      },
      {
        path: '/ancareadvantage',
        name: 'ancareadvantage',
        text: '安肯优势',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/pages/mobile/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../mobile/ancareadvantage'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/components/PageLoading/index')
                .default,
            })
          : require('../mobile/ancareadvantage').default,
        exact: true,
      },
      {
        path: '/ancarestore',
        name: 'ancarestore',
        text: '线下门店',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/pages/mobile/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../mobile/ancarestore'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website-mobile/src/components/PageLoading/index')
                .default,
            })
          : require('../mobile/ancarestore').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
